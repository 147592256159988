<template>
  <div @keydown.enter="loginUser">
    <BaseField>
      <BaseInput placeholder="Email address" :model-value="email" @update:model-value="email = $event"/>
    </BaseField>
    <BaseField :error="formError">
      <BaseInput type="password" placeholder="Password" :model-value="password" @update:model-value="password = $event"/>
    </BaseField>
    <BaseButton @click="loginUser" class="login-form__main-button" text="Continue planting trees"/>
  </div>
</template>


<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import BaseInput from '@/components/BaseInput.vue';
import BaseField from '@/components/BaseField.vue';
import BaseButton from '@/components/BaseButton.vue';
import Analytics from '@/helpers/analytics';
import { emitLogin } from '@/helpers/helpers';

@Options({
  components: {
    BaseInput,
    BaseField,
    BaseButton,
  },
  data() {
    return {
      email: '',
      password: '',
      formError: ''
    }
  },
  methods: {
    loginUser() {
      this.formError = '';

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Basic ${btoa(unescape(encodeURIComponent(this.email + ':' + this.password)))}`);

      fetch("https://ourearth.io/api/auth/login/", {
        method: 'POST',
        headers: myHeaders,
        body: JSON.stringify({ source: 'EXTENSION' }),
        redirect: 'follow'
      }).then((response: any) => {
        return response.json();
      }).then((result: any) => {
        if (result) {
          if (result.token) {
            Analytics.setUser(result.user)
            Analytics.login('success', 'app.ourforest.io', result.user.email)
            emitLogin(result);
            this.$emit('success', result)
          }
          else if (result.detail) {
            Analytics.login('error', 'app.ourforest.io', result.detail.toString());
            this.formError = result.detail;
          }
          else {
            Analytics.login('error', 'app.ourforest.io', 'invalid email/password')
            this.formError = 'Invalid email/password.';
          }
        }
      })
    }
  },
})
export default class Login extends Vue {

}
</script>

<style lang="scss">
.login-form__main-button {
  margin-top: 24px;
}
</style>