
import { Options, Vue } from 'vue-class-component';
import BaseButton from '../components/BaseButton.vue';
import BaseInput from '../components/BaseInput.vue';
import analytics from '@/helpers/analytics';

@Options({
  components: {
    BaseButton,
    BaseInput,
  },
  props: {
    userRefLink: {
      type: String,
      default: '',
      required: false
    }
  },
  data() {
    return {
      copyButtonText: 'Copy',
    }
  },
  methods: {
    copyReferral() {
      this.copyButtonText = 'Copied!';
      setTimeout(() => (this.copyButtonText = 'Copy'), 500);
      const copyText = (text: string) => {
        const dummy = document.createElement('textarea');
        document.body.appendChild(dummy);
        dummy.value = text;
        dummy.select();
        document.execCommand('copy');
        document.body.removeChild(dummy);
      };
      copyText(this.userRefLink);
      analytics.copiedReferralLink(this.userRefLink);
    },
    openLink(url: string) {
      window.open(url);
      analytics.clickedLink(url);
    }
  }
})
export default class Welcome extends Vue {

}
