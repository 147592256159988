<template>
  <div class="referral-section">
    <div class="referral-section__referral-input-wrapper" @click="copyReferral()">
      <BaseInput class="review-view__referral-input" type="text" :modelValue="userRefLink"></BaseInput>
      <BaseButton :text="copyButtonText"></BaseButton>
    </div>
    <div class="referral-section__social-links-wrapper">
      <a @click="openLink('https://facebook.com')" class="base-link"><img src="@/assets/facebook.svg"/></a>
      <a @click="openLink('https://instagram.com')" class="base-link"><img src="@/assets/instagram.svg"/></a>
      <a @click="openLink('https://twitter.com')" class="base-link"><img src="@/assets/twitter.svg"/></a>
      <a @click="openLink('https://linkedin.com')" class="base-link"><img src="@/assets/linkedin.svg"/></a>
      <a @click="openLink('https://messenger.com')" class="base-link"><img src="@/assets/messenger.svg"/></a>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import BaseButton from '../components/BaseButton.vue';
import BaseInput from '../components/BaseInput.vue';
import analytics from '@/helpers/analytics';

@Options({
  components: {
    BaseButton,
    BaseInput,
  },
  props: {
    userRefLink: {
      type: String,
      default: '',
      required: false
    }
  },
  data() {
    return {
      copyButtonText: 'Copy',
    }
  },
  methods: {
    copyReferral() {
      this.copyButtonText = 'Copied!';
      setTimeout(() => (this.copyButtonText = 'Copy'), 500);
      const copyText = (text: string) => {
        const dummy = document.createElement('textarea');
        document.body.appendChild(dummy);
        dummy.value = text;
        dummy.select();
        document.execCommand('copy');
        document.body.removeChild(dummy);
      };
      copyText(this.userRefLink);
      analytics.copiedReferralLink(this.userRefLink);
    },
    openLink(url: string) {
      window.open(url);
      analytics.clickedLink(url);
    }
  }
})
export default class Welcome extends Vue {

}
</script>

<style lang="scss">
.referral-section__referral-input-wrapper {
  display: flex;
  flex-direction: row;
  margin-top: 1em;
  margin-bottom: 1em;
}
.referral-section__referral-input-wrapper .base-button {
  max-width: 80px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.referral-section__referral-input-wrapper .base-input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.referral-section__social-links-wrapper {
  padding-top: 12px;
}
.referral-section__social-links-wrapper .base-link {
  margin-left: 8px;
  margin-right: 8px;
}
</style>