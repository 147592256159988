
import { Options, Vue } from 'vue-class-component';
import BaseCard from '@/components/BaseCard.vue';
import BaseButton from '@/components/BaseButton.vue';
import BaseInput from '@/components/BaseInput.vue';
import LoginForm from '@/components/LoginForm.vue';
import Confetti from '@/components/Confetti.vue';
import ReferralSection from '@/components/ReferralSection.vue';

@Options({
  title: 'Review',
  components: {
    BaseCard,
    BaseButton,
    BaseInput,
    LoginForm,
    Confetti,
    ReferralSection,
  },
  data() {
    return {
      userEmail: null,
      userToken: null,
      userBalance: null,
      userRefLink: null,
      pastedReview: null,
      isSubmitted: false,
      hasReviewed: false,
      formError: null,
      formLoading: false,
      hideLogin: true,
    };
  },
  mounted() {
    document.dispatchEvent(new CustomEvent("requestOurforestUserEmail", {}));
    document.addEventListener('ourforestUserEmail', (event: any) => {
      this.userEmail = event.detail.email;
      this.userToken = event.detail.token;
    });
    setTimeout(() => this.hideLogin = false, 500);
  },
  computed: {
    isSubmitDisabled() {
      return Boolean(this.pastedReview)
    }
  },
  methods: {
    submitForm() {
      this.formError = null;
      this.formLoading = true;
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Access ${this.userToken}`);
      
      fetch("https://ourearth.io/api/review/check_review/", {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
        body: JSON.stringify({"review":this.pastedReview}),
      }).then(result => {
        if (result.status === 201) this.isSubmitted = true;
        if (result.status === 404) {
          this.formError = `
          We can't find your review on the Google Chrome Store. Did you paste your review correctly?<br><br>
          If you think there has been a mistake, please contact us at <a class="base-link" href="mailto:support@ourforest.io">support@ourforest.io</a> and we'll be happy to assist you.`
        }
        if (result.status === 200) {
          this.formError = `You've already claimed a tree for writing a review – thank you!<br><br>
          If you think there has been a mistake, please contact us at <a class="base-link" href="mailto:support@ourforest.io">support@ourforest.io</a> and we'll be happy to assist you.`
        }
        this.formLoading = false;
      })
    },
    getProfile() {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Access ${this.userToken}`);
      fetch("https://ourearth.io/api/user/profile", {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
      })
      .then(response => response.json())
      .then(result => {
        this.userBalance = Math.floor(Number(result.balance.trees));
        this.userRefLink = `https://app.ourforest.io/invite/?referrer=${result.referral_code}`;
      })
    },
  },
  watch: {
    pastedReview() {
      if (this.pastedReview === null || this.pastedReview.length === 0) {
        this.formError = null;
      }
    },
    userToken() {
      if (this.userToken) {
        this.getProfile();
      }
    },
    isSubmitted() {
      if (this.isSubmitted) {
        setTimeout(() => this.userBalance += 1, 500);
      }
    }
  }
})
export default class Review extends Vue {

}
