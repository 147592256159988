<template>
  <div class="review-view">
    <Confetti v-if="isSubmitted" class="review-view__confetti" />
    <h1 class="review-view__title" style="margin-top:80px;" v-if="userEmail !== null && !isSubmitted">How to plant a tree for writing a Google Chrome Store review:</h1>
    <BaseCard class="review-view__card login-card" v-if="userEmail === null && !hideLogin">
      <h3 class="title mb-2">Log into OurForest! 🌲</h3>
      <p class="description">Before writing a review, please log in.</p>
      <LoginForm @success="[userEmail = $event.user.email, userToken = $event.token]"/>
    </BaseCard>
    <template v-if="userEmail !== null && !isSubmitted">
      <BaseCard class="review-view__card">
        <h3 class="title mb-2">Step 1 – Write a Review</h3>
        <p class="description">Visit the Google Chrome Store and write a review.</p>
        <BaseButton target="_blank" href="https://chrome.google.com/webstore/detail/ourforest-%E2%80%94-automatically/cjgphmckkkgnlkbdaellanfoajipefnd" text="Take me there!"></BaseButton>
      </BaseCard>
      <BaseCard class="review-view__card">
        <h3 class="title mb-2">Step 2 – Validate Your Review</h3>
        <p class="description">Let our system validate your review exists.</p>
        <template v-if="!hasReviewed">
          <BaseButton class="secondary" @click="hasReviewed = true" text="Validate my review"></BaseButton>
        </template>
        <template v-else>
          <div class="base-textarea" :class="formError? 'base-textarea__error' : ''">
            <div v-if="formLoading" class="base-textarea--loading-overlay">
              <div class="sk-flow">
                <div class="sk-flow-dot"></div>
                <div class="sk-flow-dot"></div>
                <div class="sk-flow-dot"></div>
              </div>
            </div>
            <textarea v-model="pastedReview" placeholder="Paste your review in here" name="review_collection" id="" cols="30" rows="10"></textarea>
          </div>
          <p v-if="formError" class="base-textarea--form-error" v-html="formError"></p>
          <BaseButton :class="isSubmitDisabled? '' : 'disabled'" @click="isSubmitDisabled? submitForm(): ''" text="Plant my tree"></BaseButton>
        </template>
      </BaseCard>
    </template>
    <BaseCard v-if="userEmail !== null && isSubmitted" class="review-view__card" style="margin-top:80px;">
      <h3 class="title">Thanks for your review!</h3>
      <p class="description mb-2">We appreciate your feedback, so to say thanks, here's an extra tree on us.</p>
      <h1 class="review-view__user-tree-balance header">{{ userBalance }}</h1>
      <h5 class="title mb-3">YOUR FOREST 🌲</h5>
      <hr>
      <h4>Want to make an even bigger impact?</h4>
      <p class="description">Invite friends and we'll plant 10 trees for each person that joins OurForest. That's five for you and five for them!</p>
      <ReferralSection :userRefLink="userRefLink"></ReferralSection>
    </BaseCard>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import BaseCard from '@/components/BaseCard.vue';
import BaseButton from '@/components/BaseButton.vue';
import BaseInput from '@/components/BaseInput.vue';
import LoginForm from '@/components/LoginForm.vue';
import Confetti from '@/components/Confetti.vue';
import ReferralSection from '@/components/ReferralSection.vue';

@Options({
  title: 'Review',
  components: {
    BaseCard,
    BaseButton,
    BaseInput,
    LoginForm,
    Confetti,
    ReferralSection,
  },
  data() {
    return {
      userEmail: null,
      userToken: null,
      userBalance: null,
      userRefLink: null,
      pastedReview: null,
      isSubmitted: false,
      hasReviewed: false,
      formError: null,
      formLoading: false,
      hideLogin: true,
    };
  },
  mounted() {
    document.dispatchEvent(new CustomEvent("requestOurforestUserEmail", {}));
    document.addEventListener('ourforestUserEmail', (event: any) => {
      this.userEmail = event.detail.email;
      this.userToken = event.detail.token;
    });
    setTimeout(() => this.hideLogin = false, 500);
  },
  computed: {
    isSubmitDisabled() {
      return Boolean(this.pastedReview)
    }
  },
  methods: {
    submitForm() {
      this.formError = null;
      this.formLoading = true;
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Access ${this.userToken}`);
      
      fetch("https://ourearth.io/api/review/check_review/", {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
        body: JSON.stringify({"review":this.pastedReview}),
      }).then(result => {
        if (result.status === 201) this.isSubmitted = true;
        if (result.status === 404) {
          this.formError = `
          We can't find your review on the Google Chrome Store. Did you paste your review correctly?<br><br>
          If you think there has been a mistake, please contact us at <a class="base-link" href="mailto:support@ourforest.io">support@ourforest.io</a> and we'll be happy to assist you.`
        }
        if (result.status === 200) {
          this.formError = `You've already claimed a tree for writing a review – thank you!<br><br>
          If you think there has been a mistake, please contact us at <a class="base-link" href="mailto:support@ourforest.io">support@ourforest.io</a> and we'll be happy to assist you.`
        }
        this.formLoading = false;
      })
    },
    getProfile() {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Access ${this.userToken}`);
      fetch("https://ourearth.io/api/user/profile", {
        method: 'POST',
        headers: myHeaders,
        redirect: 'follow',
      })
      .then(response => response.json())
      .then(result => {
        this.userBalance = Math.floor(Number(result.balance.trees));
        this.userRefLink = `https://app.ourforest.io/invite/?referrer=${result.referral_code}`;
      })
    },
  },
  watch: {
    pastedReview() {
      if (this.pastedReview === null || this.pastedReview.length === 0) {
        this.formError = null;
      }
    },
    userToken() {
      if (this.userToken) {
        this.getProfile();
      }
    },
    isSubmitted() {
      if (this.isSubmitted) {
        setTimeout(() => this.userBalance += 1, 500);
      }
    }
  }
})
export default class Review extends Vue {

}
</script>

<style lang="scss">
@import '@/design/spinkit.scss';

.review-view {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
}
.review-view__title {
  max-width: 400px;
  text-align: center;
  color: #3B6F06;
}
.review-view__card:first-child {
  margin-top: 80px;
}
.review-view__card {
  margin-top: 20px;
  max-width: 400px;
  text-align: center;
}
.review-view__card.inactive {
  color: gray;
}
.review-view__card--skeleton {
  display: block;
  height: 16px;
  background: #F2F2F2;
  border-radius: 2px;
  width: 80%;
  animation: flickerAnimation 2s infinite;
}
@keyframes flickerAnimation {
  0%   { opacity:1; }
  50%  { opacity:0; }
  100% { opacity:1; }
}
.base-textarea {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 200px;
  background: #FFFFFF;
  border: 1px solid #DBDBDB;
  box-sizing: border-box;
  border-radius: 3px;
  overflow: hidden;
  padding: 8px;
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
}
.base-textarea textarea {
  flex: 1;
  outline: 0;
  border: 0;
  font-size: 16px;
  font-family: inherit;
  resize: none;
}
.base-textarea.base-textarea__error {
  border-color: #b61210;
}
.base-textarea--form-error {
  padding: 8px;
  border-radius: 3px;
  border: 1px solid #b61210;
  background: #F8E7E7;
}
.base-textarea--form-error .base-link {
  color: #b61210;
}
.base-textarea--loading-overlay {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 20px;
  left: 0;
}
.base-textarea--loading-overlay .sk-flow-dot {
  background: #64B60E;
}
.review-view__card--step-image {
  width: 100%;
}
.review-view__confetti {
  position: absolute;
  top: -80px;
  pointer-events: none;
}
.review-view__confetti .confetti_container--confetti {
  animation-iteration-count: 1;
}
.review-view__user-tree-balance {
  animation: colorAnimation 3s;
  animation-fill-mode: forwards;
}
@keyframes colorAnimation {
  0%   { color: unset; }
  100% { color: #6DC70F; }
}
</style>